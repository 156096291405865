/* import layout */
import Layout from '../Layout/Layout'
import PlainLayout from '../Layout/PlainLayout'

/* import all modules componet */

import IndividualTeam from '../Modules/IndividualTeam';
import Team from '../Modules/Team';

const Nav = [{
    exact: true,
    content: '隊伍總覽',
    path: '/team',
},];

const RouterConfig = [
    
    //Chief Judge All Team View Page
    {
        Path: '/team',
        Exact: true,
        Layout,
        Content: Team,
        Nav,
    },

    //Helper Page
    {
        Path: '/team/:team_code',
        Layout: PlainLayout,
        Content: IndividualTeam,
    },
]

export default RouterConfig;