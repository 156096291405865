import moment from 'moment';

export const diffToStr = (momentDiff) => {
    const diff = moment.duration(momentDiff);

    const minute = diff.minutes();
    const second = diff.seconds();
    
    return `${minute<10?0:''}${minute}:${second<10?0:''}${second}`;
}

export const timeToMoment = (date) => {
    return date?moment(date):moment();
}

export const now = () => {
    return moment();
}

export const countdown = (t) => {
    
    const a = moment(t).add(3, 'm');
    let b = now();

    const rawdiff = a.diff(b)

    if(rawdiff<0) return '00:00';
    
    return diffToStr(rawdiff);
}