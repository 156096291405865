import React from "react";
import socket from "../Helper/ApiHelper";
import { UserContext } from "./user-context";

import { inputHandler } from '../Helper/FormHelper';
import _Component from "../Components/_Component";
import ErrorLabel from '../Components/ErrorLabel';

import { Modal, Form, Button, Segment } from 'semantic-ui-react';
export default class MatchProvider extends _Component {
	constructor() {
		super();
		this.default = {
			loggedIn: null,
            passphrase: ''
		};
		this.state = this.default;
		this.state.signIn = this.signIn.bind(this);
	}

	componentDidMount(){
        socket.on('signInResult', loggedIn=>{
            this.setState({
                loggedIn,
                passphrase: ''
            });
		});
		socket.on('returnSession', loggedIn=>{
            this.setState({
                loggedIn
            });
		})
		socket.emit('resumeSession');
	}

    /* input update handler */
    inputChange = (inputType, stateName) => (event, data) => {      
        let value = inputHandler(inputType, data);
        this.setState({ [stateName]: value })
    }

    handleKeyPress = (event) => {
        if(event.key === 'Enter')
            this.signIn();
    }

	signIn = () =>{
		socket.emit(`signIn`, this.state.passphrase);
	}

	render() {
        const {
            loggedIn,
            passphrase,
        } = this.state;
		if(!loggedIn)
			return (
                <Modal open={true} closeOnDimmerClick={false}>
                    <Modal.Header>請輸入密碼</Modal.Header>
                    <Modal.Content>
                        <Segment basic>
                            <Form>
                                <Form.Group className='form-group-margin' grouped>
                                    <Form.Input
                                        type='password'
                                        value={passphrase}
                                        onChange={(event,data)=>{
                                            this.inputChange('text', 'passphrase')(event, data);
                                            this.setState({loggedIn: null});
                                        }}
                                        label='密碼'
                                        placeholder='密碼'
                                        onKeyPress={this.handleKeyPress}
                                    />
                                    {loggedIn===false && <ErrorLabel message='密碼錯誤，請重新輸入'/>}
                                </Form.Group>
                            </Form>
                        </Segment>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='green' content='登入' icon='sign in alternate' onClick={this.signIn} />
                    </Modal.Actions>    
                </Modal>
			);

		return (
			<UserContext.Provider
				value={{
					userContext: {
						...this.state
					}
				}}
			>
				{this.props.children}
			</UserContext.Provider>
		);
	}
}