import React from 'react';

/* import semantic-ui element */
import { Input, Button } from 'semantic-ui-react';

/* import helper functions */
import socket from '../Helper/ApiHelper';
import {now} from '../Helper/TimeHelper';
import _Component from './_Component';

class Increment extends _Component{
    constructor(props){
        super(props);
        this.state = {
            isCoolDown: false,
            min: props.min||0,
            max: props.max||undefined
        }
    }
    setCoolDown = () => {
        if(!this.willUnmount){
            this.setState({isCoolDown: true});
            window.setTimeout(()=>{
                if(!this.willUnmount){
                    this.setState({isCoolDown: false});
                }
            }, 1000);
        }
    }
    render(){
        const {isCoolDown, min, max} = this.state;
        const {count, id, method, isChief, disabled} = this.props;
        return (
            <Input labelPosition='left' type='text' value={count} disabled fluid className='increment'>
                <Button disabled={disabled||isCoolDown||count===max} content='+' compact color='green' onClick={
                    ()=>{
                        socket.emit(method,{
                            id: id,
                            method: 'add',
                            isChief: isChief,
                            last_update: now()
                        });
                        this.setCoolDown();
                    }
                }/>
                <input/>
                <Button disabled={disabled||isCoolDown||count===min} content='-' compact color='red' onClick={
                    ()=>{
                        socket.emit(method,{
                            id: id,
                            method: 'sub',
                            isChief: isChief,
                            last_update: now()
                        });
                        this.setCoolDown();
                    }
                }/>
            </Input>
        );
    }
}

export default Increment;