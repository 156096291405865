/* import react */
import React from 'react';

/* import router component */
import Router from './Router/Router';
import UserProvider from './Context/UserProvider';

const App = (props) => (
    <UserProvider>
        <Router/>
    </UserProvider>
)

export default App;