/* import react */
import React, { Fragment } from 'react';

/* import module nav */
import Nav from '../Components/Nav';

/* import semantic-ui element */
import {
    Container,
    Grid
} from 'semantic-ui-react';

const Layout  = (props) => (
    <Fragment>
        <Nav items={props.nav} inverted/>
        {props.subnav && (
            <Nav
                secondary
                size='large'
                items={props.subnav}
        />)}
        <Grid celled='internally' id='main'>
            <Container>
                {props.children}
            </Container>
        </Grid>
    </Fragment>
)

export default Layout;