/* import react */
import { Component } from 'react';
class _Component extends Component {
    constructor(props) {
        super(props);
        this.willUnmount = false;
    }
    componentWillUnmount = () =>{
        this.willUnmount = true;
    }
}
export default _Component;