/* import react */
import React from 'react';

/* import semantic-ui element */
import { Table } from 'semantic-ui-react';

const EmptyTableMsg = ({colSpan = 1, msg='沒有記錄'}) => {
    return (
       <Table.Row>
            <Table.Cell colSpan={colSpan} className='empty'>
                <b>{msg}</b>
            </Table.Cell>
        </Table.Row>
    )
}

export default EmptyTableMsg;