/* import react */
import React from 'react';

/* import semantic-ui element */
import {
    Grid
} from 'semantic-ui-react';

const PlainLayout = (props) => (
    <Grid id='main' style={{display: 'flex', alignItems: 'center'}}>
        {props.children}
    </Grid>
)

export default PlainLayout;