/* import react */
import React, { Fragment } from 'react';
import { withRouter } from 'react-router';

/* import components */
import _Component from '../Components/_Component';
import EmptyTableMsg from '../Components/EmptyTableMsg';
import ResponsiveTable from '../Components/ResponsiveTable';
import Increment from '../Components/Increment';
import Countdown from '../Components/Countdown';
import ConfirmModal from '../Components/ConfirmModal';

/* import Context */
//import {withMatch} from '../Context/withMatch';

/* import helper functions */
import socket from '../Helper/ApiHelper';
import { inputHandler } from '../Helper/FormHelper';
import { diffToStr, timeToMoment } from '../Helper/TimeHelper';

/* import semantic-ui element */
import { Grid, Table, Button } from 'semantic-ui-react';

class IndividualTeam extends _Component {
    constructor(props) {
        super(props);
        this.state = {
            errStr: '未有比賽',

            teamInfo: {},
            validTeam: null,
            countdownText: '',

            isStartModalOpen: false,
            isEndModalOpen: false,
        }

        this.tableData = [
            {
                headerName: '隊伍編號',
                genField: 'team_code',
            },
            /*{
                headerName: '隊伍名稱',
                genField: 'name',
            },*/
            {
                headerName: '到達對岸',
                genField: (x=>(
                    <Increment
                        method='otherSide'
                        count={x.to_other_side}
                        id={x.id}
                        disabled={x.dq}
                    />
                )),
                noMatch: (x=>x.hasOwnProperty('to_other_side')&&!x.is_pending_rematch?x.to_other_side:'N/A'),
            },
            {
                headerName: '成功拯救',
                genField: (x=>(
                    <Increment
                        method='successfulSave'
                        count={x.nice_save}
                        id={x.id}
                        disabled={x.dq}
                    />
                )),
                noMatch: (x=>x.hasOwnProperty('nice_save')&&!x.is_pending_rematch?x.nice_save:'N/A'),
            },
            {
                headerName: '借用紙皮板',
                genField: (x=>(
                    <Increment
                        method='borrowBoard'
                        count={x.get_cardboard}
                        id={x.id}
                        disabled={x.dq}
                        max={1}
                    />
                )),
                noMatch: (x=>x.hasOwnProperty('get_cardboard')&&!x.is_pending_rematch?x.get_cardboard:'N/A'),
            },
            {
                headerName: '總分',
                genField: (x => {
                    if(x.is_pending_rematch||x.dq)return 0;
                    const score = (x.dq?0:+x.to_other_side+2*x.nice_save-(x.get_cardboard&&10))||0;
                    return score>=0?score:<Fragment><del>{score}</del> <ins>0</ins></Fragment>;
                })
            },
            {
                headerName: '最後更新',
                genField: (x => x.last_update&&!x.is_pending_rematch?
                    diffToStr(timeToMoment(x.last_update).diff(timeToMoment(x.start_time))):
                    'N/A'
                ),
            },
        ];
    }

    componentDidMount = () => {
        socket.on('returnTeamWithLatestMatchByTeamCode', data => {
            if(!this.willUnmount){
                if(this.state.validTeam==null){
                    socket.on(`${data&&data.team_id}rematch`, () => {
                        socket.emit('getTeamWithLatestMatchByTeamCode', this.getTeamCode())
                        if(!this.willUnmount) this.setState({isEndModalOpen: false})
                    });
                }
                this.setState({
                    teamInfo: data,
                    validTeam: Object.keys(data).length!==0,
                })
            }
        })
        socket.on('matchInfoChanged', ({id, team_id}) => {
            if(team_id && team_id!==this.state.teamInfo.team_id) return;
            socket.emit('getTeamWithLatestMatchByTeamCode', this.getTeamCode())
        });

        socket.on('matchStarted', () => {
            socket.emit('getTeamWithLatestMatchByTeamCode', this.getTeamCode());
            if(!this.willUnmount) this.setState({isStartModalOpen: false})
        });
        socket.on('matchEnded', () => {
            socket.emit('getTeamWithLatestMatchByTeamCode', this.getTeamCode())
            if(!this.willUnmount) this.setState({isEndModalOpen: false})
        });
        socket.emit('getTeamWithLatestMatchByTeamCode', this.getTeamCode());
    }

    getTeamCode = () => {
        return this.props.match.params.team_code;
    }

    getTeamId = () => {
        return this.state.teamInfo.team_id;
    }

    getMatchId = () => {
        return this.state.teamInfo.id;
    }

    /* modal toggle */
    modalToggle = (modalStateName, enabled) => () => {
        if(!this.willUnmount){
            this.setState({
                [modalStateName]:  enabled
            });
        }
    }

    /* input update handler */
    inputChange = (inputType, stateName) => (event, data) => {      
        let value = inputHandler(inputType, data);
        this.setState({ [stateName]: value })
    }

    start = () => {
        const start_time = new Date();
        socket.emit('startMatch', {team_id: this.getTeamId(), start_time});
        this.modalToggle('isStartModalOpen', false)();
    }

    end = () => {
        const end_time = new Date();
        socket.emit('endMatch', {match_id: this.getMatchId(), end_time});
        this.modalToggle('isEndModalOpen', false)();
    }

    onTimeout = () => {
        if(!this.state.teamInfo.end_time){
            this.modalToggle('isEndModalOpen', true)();
        }
    }

    render() {
        const {
            validTeam,
            teamInfo,
            isStartModalOpen,
            isEndModalOpen,
            errStr
        } = this.state;

        return (
        <Fragment>
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Countdown
                            start_time={teamInfo.start_time}
                            end_time={teamInfo.end_time}
                            onTimeout={this.onTimeout}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Button
                            disabled={
                                !!teamInfo.start_time!==!!teamInfo.end_time ||
                                !!teamInfo.end_time!==!!teamInfo.is_pending_rematch
                            }
                            color='orange'
                            content='開始比賽'
                            icon='flag'
                            onClick={this.modalToggle('isStartModalOpen', true)}
                        />
                        <Button
                            disabled={!teamInfo.start_time||!!teamInfo.end_time}
                            color='red'
                            content='結束比賽'
                            icon='hourglass end'
                            onClick={this.modalToggle('isEndModalOpen', true)}
                        />
                    </Grid.Column>
                </Grid.Row>
                <ResponsiveTable textAlign='center' fixed selectable celled unstackable columnInfo={this.tableData.map(x=>x.headerName)}>
                    <Table.Header>
                        <Table.Row>
                            {this.tableData.map(x=><Table.HeaderCell key={x.headerName} content={x.headerName}/>)}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {validTeam?(
                                <Table.Row>
                                    {this.tableData.map((x,i,a)=>{
                                        let key = (teamInfo.start_time&&!teamInfo.end_time)||!x.hasOwnProperty('noMatch')?'genField':'noMatch';

                                        let content = null;
                                        if(typeof x[key] === 'string')
                                            content = teamInfo[x[key]];
                                        else
                                            content = x[key](teamInfo);
                                        
                                        return <Table.Cell key={x.headerName} {...{content}} />
                                    })}
                                </Table.Row>
                            )
                            :
                            <EmptyTableMsg colSpan={this.tableData.length} msg={errStr} />
                        }
                    </Table.Body>
                </ResponsiveTable>
            </Grid>
            <ConfirmModal
                open={isStartModalOpen}
                description={`確定開始比賽？本操作不可逆轉！`}
                cancel={this.modalToggle('isStartModalOpen', false)}
                confirmIcon='flag'
                confirmText='開始'
                confirm={this.start}
            />
            <ConfirmModal
                open={isEndModalOpen}
                description={`您需要結束比賽嗎？本操作不可逆轉，結束比賽後將無法更改分數！`}
                cancel={this.modalToggle('isEndModalOpen', false)}
                confirmIcon='hourglass end'
                confirmText='結束'
                confirm={this.end}
            />
        </Fragment>)
    }
}

export default withRouter(IndividualTeam);//withRouter(withMatch(IndividualTeam));