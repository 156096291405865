/* import react */
import React from 'react';

/* import semantic-ui element */
import {Modal, Segment, Button } from 'semantic-ui-react';

const ConfirmModal = ({open, description, cancel, confirm, confirmText='刪除', confirmIcon='remove', cancelText='取消', cancelIcon='cancel'}) => {
    return (
        <Modal open={open} onClose={cancel}>
            <Modal.Content>
                <Segment basic>
                    {description}
                </Segment>
            </Modal.Content>
            <Modal.Actions>
                <Button color='red' content={cancelText} icon={cancelIcon} onClick={cancel} />
                <Button color='green' content={confirmText} icon={confirmIcon} onClick={confirm} />
            </Modal.Actions>    
        </Modal>
    )
}

export default ConfirmModal;