/* import react */
import React, { Component } from 'react';
import { withRouter } from 'react-router';

/* import semantic-ui element */
import {
    Menu,
} from 'semantic-ui-react'

class Nav extends Component {
    
    navigation = (route) => () => {
        this.props.history.push(route);
    }

    render() {
        const { 
            location: {pathname},
            items,
            color, inverted, size
        } = this.props;

        return (!!items) && (
            <Menu
                secondary={this.props.secondary}
                {...{color,inverted}}
                size={size||'huge'}
                borderless
            >
                {items.map(x=>(
                    <Menu.Item
                        key={x.content}
                        content={x.content}
                        onClick={this.navigation(x.path)}
                        active={pathname.slice(pathname.length-x.path.length)===x.path}
                    />
                ))}
            </Menu>
        )
    }
}

export default withRouter(Nav);