/* import helper */
import moment from 'moment';

/* common input handler */
export const inputHandler = (inputType, data) => {
    // date
    if(inputType === 'date') { 
        return moment(data.value, 'DD-MM-YYYY'); 
    }
    
    // text
    if(inputType === 'text') { 
        return data.value 
    }

    // number
    if(inputType === 'number') { 
        if(data.value || data.value === 0) {
            return data.value 
        }else{
            return;
        }
    }

    // toggle
    if(inputType === 'toggle') {
        return data.checked;
    }

    // select
    if(inputType === 'select') {
        return data.value
    }

    // raido button
    if(inputType === 'radio') {
        let value = data.value;
        if(value === 'true') {
            value = true;
        }
        if(value === 'false') {
            value = false;
        }
        return value
    }

    // checkbox
    if(inputType === 'checkbox') {
        return data.checked;
    }
}