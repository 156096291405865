/* import react */
import React from 'react';

/* import semantic-ui element */
import { Header } from 'semantic-ui-react';

import {countdown} from '../Helper/TimeHelper';
import _Component from './_Component';

class Countdown extends _Component{
    constructor(props){
        super(props);
        this.state = {intervalId: null}
        if(props.start_time &&countdown(this.props.start_time)!=='00:00' && !props.end_time){ //match start
            const intervalId = window.setInterval(()=>{
                this.forceUpdate();
            }, 1000);
            this.state = {intervalId};
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.start_time !== this.props.start_time){ //match start
            const intervalId = window.setInterval(()=>{
                this.forceUpdate();
            }, 1000);

            if(!this.willUnmount){
                this.setState({intervalId});
            }
        }

        if(
            ((!prevProps.end_time && this.props.end_time)||(this.props.start_time&&countdown(this.props.start_time)==='00:00'))&&
            this.state.intervalId
        ){ //match end
            window.clearInterval(this.state.intervalId);
            if(this.props.hasOwnProperty('onTimeout'))
                this.props.onTimeout();
            if(!this.willUnmount){
                this.setState({intervalId: null});
            }
        }
    }
    
    render(){
        const {start_time, end_time} = this.props;

        const countdownText = start_time?(end_time?'比賽結束':`剩餘時間：${countdown(start_time)}`):'比賽即將開始';

        return <Header as="h1" textAlign="center" color="orange" id="countdown">{countdownText}</Header>;
    }

}

export default Countdown;