/* import react */
import React from 'react';

/* import react router */
import { BrowserRouter, Route, Switch } from 'react-router-dom';

/* import scroll to top component */
import ScrollToTop from './ScrollToTop';

/* import router config */
import RouterConfig from './RouterConfig';

const Router = (props) => (
    <BrowserRouter>
        <ScrollToTop>
            <Switch>
                {RouterConfig.map(({Path, Exact, Layout, Content, Nav, SubNav}, index) => {
                    return (
                        <Route key={index} path={Path} exact={Exact} render={() => {
                            return (
                                <Layout nav={Nav} subnav={SubNav}>
                                    {Content && <Content/>}
                                </Layout>
                            )
                        }} />
                    )
                })}
            </Switch>
        </ScrollToTop>
    </BrowserRouter>
);

export default Router;