/* import lodash */
import _ from 'lodash';

/* import react */
import React, { Fragment } from 'react';

/* import components */
import _Component from '../Components/_Component';
import EmptyTableMsg from '../Components/EmptyTableMsg';
import ResponsiveTable from '../Components/ResponsiveTable';
import Increment from '../Components/Increment';
import ConfirmModal from '../Components/ConfirmModal';

/* import helper functions */
import socket from '../Helper/ApiHelper';
import {diffToStr, timeToMoment} from '../Helper/TimeHelper';
import { inputHandler } from '../Helper/FormHelper';

/* import semantic-ui element */
import { Grid, Table, Button, Input, Select } from 'semantic-ui-react';

class Team extends _Component {
    constructor(props) {
        super(props);
        
        this.tableData = [
            {
                headerName: '隊伍編號',
                genField: 'team_code'
            },
            /*{
                headerName: '隊伍名稱',
                genField: 'name',
            },*/
            {
                headerName: '到達對岸',
                genField: (x=>(
                    <Increment
                        method='otherSide'
                        count={x.to_other_side}
                        id={x.id}
                        isChief={true}
                        disabled={x.dq}
                    />
                )),
                noMatch: (x=>x.hasOwnProperty('to_other_side')?x.to_other_side:'N/A'),
            },
            {
                headerName: '成功拯救',
                genField: (x=>(
                    <Increment
                        method='successfulSave'
                        count={x.nice_save}
                        id={x.id}
                        isChief={true}
                        disabled={x.dq}
                    />
                )),
                noMatch: (x=>x.hasOwnProperty('nice_save')?x.nice_save:'N/A'),
            },
            {
                headerName: '借用紙皮板',
                genField: (x=>(
                    <Increment
                        method='borrowBoard'
                        count={x.get_cardboard}
                        id={x.id}
                        isChief={true}
                        disabled={x.dq}
                        max={1}
                    />
                )),
                noMatch: (x=>x.hasOwnProperty('get_cardboard')?x.get_cardboard:'N/A'),
            },
            {
                headerName: '總分',
                genField: (x => {
                    const score = (x.dq?0:+x.to_other_side+2*x.nice_save-(x.get_cardboard&&10)) || 0;
                    return score>=0?score:<Fragment><del>{score}</del> <ins>0</ins></Fragment>;
                })
            },
            {
                headerName: '最後更新',
                genField: (x => x.last_update?
                    diffToStr(timeToMoment(x.last_update).diff(timeToMoment(x.start_time))):
                    'N/A'
                ),
            },
            {
                headerName: '取消資格',
                genField: (x => x.dq?
                    (<Button icon='history' color='green' circular onClick={this.modalToggle('isUnDQModalOpen', true, x.team_id)}/>):
                    (<Button icon='user x' color='red' circular onClick={this.modalToggle('isDQModalOpen', true, x.team_id)}/>)
                ),
            },
            {
                headerName: '重賽',
                genField: (x => (x.end_time&&!x.is_pending_rematch)?
                    (<Button icon='redo' color='green' circular onClick={this.modalToggle('isRematchModalOpen', true, x.team_id)}/>):
                    'N/A'
                )
            }
        ];

        this.sortOptions = {
            'by-team_code':  {
                text: '以隊伍編號排序',
                value: 'by-team_code',
                sort: (x=>`${x.team_code}`)
            },
            'by-score_aggregate': {
                text: '以較高分數排序',
                value: 'by-score_aggregate',
                sort: [
                    x=>+x.dq, //dq last
                    x=>-Math.max(0,+x.to_other_side+2*x.nice_save-(x.get_cardboard&&10)), //desc order of score
                    x =>(x.last_update?diffToStr(timeToMoment(x.last_update).diff(timeToMoment(x.start_time))):'N/A'), //asc order of time string
                ]
            }
        };

        this.state = {
            selectedId: null,

            teamMatchInfo: [],

            isDQModalOpen: false,
            isUnDQModalOpen: false,
            isRematchModalOpen: false,

            sortColumn: null,
            sortDirection: null,

            filterStr: '',
            sortMethod: 'by-team_code'
        }

    }

    componentDidMount = () => {
        
        socket.on('returnTeamWithMatch', (result)=>{
            if(!this.willUnmount)
                this.setState({teamMatchInfo: result});
        });
        socket.on('dqSuccess', (data)=>{
            socket.emit('getTeamWithMatch');
            if(!this.willUnmount)
                this.setState({isDQModalOpen: false});
        });
        socket.on('undqSuccess', (data)=>{
            socket.emit('getTeamWithMatch');
            if(!this.willUnmount)
                this.setState({isUnDQModalOpen: false});
        });
        socket.on('rematchSuccess', (data)=>{
            socket.emit('getTeamWithMatch');
            if(!this.willUnmount)
                this.setState({isRematchModalOpen: false});
        });

        socket.on('matchInfoChanged', (mode)=>{
            socket.emit('getTeamWithMatch');
        });

        socket.emit('getTeamWithMatch');
    }

    dq = (id) => () => {
        socket.emit('dqTeam', id);
    }

    undq = (id) => () => {
        socket.emit('unDqTeam', id);
    }
    
    rematch = (id) => () => {
        const start_time = new Date();
        socket.emit('rematch', {team_id: id, start_time});
    }

    /* input update handler */
    inputChange = (inputType, stateName) => (event, data) => {      
        let value = inputHandler(inputType, data);
        this.setState({ [stateName]: value })
    }

    /* modal toggle */
    modalToggle = (modalStateName, enabled, selectedId=null) => () => {
        if(!this.willUnmount)
            this.setState({
                [modalStateName]:  enabled,
                selectedId
            });
    }

    interactiveTable = (yourTable) => (
    <Grid.Row>
        <Grid.Column>
            <ResponsiveTable textAlign='center' selectable celled unstackable columnInfo={this.tableData.map(x=>x.headerName)}>
                <Table.Header>
                    <Table.Row>
                        {this.tableData.map(x=>(
                            <Table.HeaderCell
                                key={x.headerName}
                                content={x.headerName}
                            />
                        ))}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {yourTable && yourTable.length > 0?
                        <Fragment>
                            {yourTable
                                .filter(x=>!this.state.filterStr||x.team_code.indexOf(this.state.filterStr)!==-1).sort()
                                .map(x => (
                                    <Table.Row key={x.team_code} className={x.dq?'dq':''}>
                                        {this.tableData.map(y=>{
                                            let key = !y.hasOwnProperty('noMatch')|| x.hasOwnProperty('start_time') ? 'genField' : 'noMatch';
        
                                            let content = null;
                                            if(typeof y[key] === 'string')
                                                content = x[y[key]];
                                            else
                                                content = y[key](x);
                                            
                                            return <Table.Cell key={y.headerName} {...{content}} />
                                        })}
                                    </Table.Row>
                                ))
                            }
                        </Fragment>
                        :
                        <EmptyTableMsg colSpan={this.tableData.length} msg='未能取得隊伍' />
                    }
                </Table.Body>
            </ResponsiveTable>
        </Grid.Column>
    </Grid.Row>)

    render() {
        const {
            teamMatchInfo,
            selectedId,
            isDQModalOpen,
            isUnDQModalOpen,
            isRematchModalOpen,

            filterStr,
            sortMethod
        } = this.state;

        const selectedTeam = _.find(teamMatchInfo, {team_id: selectedId});

        let gTeam = teamMatchInfo.filter(x=>x.team_code[0]==='G');
        let rTeam = teamMatchInfo.filter(x=>x.team_code[0]==='R');

        if(sortMethod && this.sortOptions[sortMethod] && this.sortOptions[sortMethod]!=='by-team_code'){
            gTeam = _.sortBy(gTeam, this.sortOptions[sortMethod].sort);
            rTeam = _.sortBy(rTeam, this.sortOptions[sortMethod].sort);
        }

        return (
            <Fragment>
                <Grid style={{paddingTop: '3rem'}}>
                    <Grid.Row>
                        <Grid.Column>
                            <Input
                                value={filterStr}
                                onChange={this.inputChange('text', 'filterStr')}
                                placeholder='以隊伍編號篩選'
                            />
                            <Select
                                value={sortMethod}
                                options={Object.values(this.sortOptions).map(x=>({text: x.text, value: x.value}))}
                                onChange={this.inputChange('select', 'sortMethod')}
                            />
                            </Grid.Column>
                    </Grid.Row>
                    {this.interactiveTable(gTeam)}
                    {this.interactiveTable(rTeam)}
                </Grid>
                <ConfirmModal
                    open={isDQModalOpen}
                    description={<span>確定要取消<span style={{color:'red'}}>{selectedTeam && selectedTeam.team_code}</span>的資格？</span>}
                    
                    confirmIcon='user x'
                    confirmText='取消資格'
                    confirm={this.dq(selectedId)}

                    cancelIcon='undo'
                    cancelText='返回'
                    cancel={this.modalToggle('isDQModalOpen', false)}
                />
                <ConfirmModal
                    open={isUnDQModalOpen}
                    description={<span>確定要恢復<span style={{color:'red'}}>{selectedTeam && selectedTeam.team_code}</span>的資格？</span>}
                    
                    confirmIcon='history'
                    confirmText='撤回DQ'
                    confirm={this.undq(selectedId)}

                    cancelIcon='undo'
                    cancelText='返回'
                    cancel={this.modalToggle('isUnDQModalOpen', false)}
                />
                <ConfirmModal
                    open={isRematchModalOpen}
                    description={<span>確定<span style={{color:'red'}}>{selectedTeam && selectedTeam.team_code}</span>要重賽嗎？</span>}
                    
                    confirmIcon='redo'
                    confirmText='重賽'
                    confirm={this.rematch(selectedId)}

                    cancel={this.modalToggle('isRematchModalOpen', false)}
                />
            </Fragment>
        )
    }
}

export default Team;